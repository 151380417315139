import debug from '@Guidelines/debug';

const debugParameters = debug('parameters');

const env =
    process.env.IMPRO_ENV ||
    process.env.STORYBOOK_ENV ||
    process.env.NODE_ENV ||
    'production';

export type Parameters = {
    api: {
        host: string;
    };
};

const parameters: Parameters = {
    api: {
        host: `https://api.improkit.com`,
    },
};

switch (env) {
    case 'development':
        parameters.api.host = `${window.location.protocol}//${window.location.hostname}:3001`;
        break;
    default:
        break;
}

debugParameters(parameters);

export default Object.freeze(parameters);
